import React, {useContext,useEffect} from "react";
import "./Demo.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTime } from "luxon";
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import Conf from 'Conf';
function Demo({ id }) {
  const { getDoc, getCollection, publishDemo, publishedDemo, fetchGitLog, gitLog } = useContext(ModelContext);
  const doc=getDoc(id);
  const portraits=getCollection('portrait').map((o)=>{return {label:o.nom || 'Sans nom',value:o._id}});
  const publish=()=>{
    console.log('publish',doc);
    publishDemo(doc,(res)=>{
      console.log('demo',res);
    });
  }
  useEffect(()=>{
    if (!gitLog) {
      fetchGitLog();
    }
  },[fetchGitLog,gitLog])
  const published= publishedDemo[doc._id]!==undefined ? publishedDemo[doc._id] : true;
  const options=gitLog ? [{label:'Dernier commit',value:-1},...gitLog.map((o)=>{
    return {
      label:DateTime.fromISO(o.date).toLocaleString()+' - '+o.message.substring(0,50),
      value:o.hash,
    }
  })] : null;
  const currentCommit= gitLog && doc.publishedCommit ? gitLog.find((o)=>o.hash===doc.publishedCommit) : null;
  return doc && doc.type==='demo' ? <>
    <Text name='Nom' docId={id} path={['nom']}/>
    <Text name='Chemin' docId={id} path={['path']}/>
    {options && <Stack direction="row" spacing={2}>
      <div style={{flexGrow:1}}>
        <Select name='Commit' docId={id} path={['commit']} options={options}/>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
      <IconButton
        size="small"
        color="inherit"
        onClick={fetchGitLog}
      >
        <RefreshIcon />
      </IconButton>
      </div>
    </Stack>}
    <Select name='Portraits' docId={id} path={['portraits']} options={portraits} multiple/>
    <Button variant="contained" onClick={publish} disabled={!publishedDemo.any}>
      Publier {!published && <>&nbsp;<CircularProgress size={20}/></>}
    </Button>
    {doc.date ? <div>
      <p>Dernière publication : {DateTime.fromMillis(doc.date).setLocale('fr').toLocaleString(DateTime.DATETIME_FULL)}</p>
      {currentCommit && <p>Commit :  {DateTime.fromISO(currentCommit.date).toLocaleString()+' - '+currentCommit.message.substring(0,50)}</p>}
      {doc.url && <p><a href={doc.url} target="_blank" rel="noreferrer">{doc.url}</a></p>}
      {doc.url && <p> <a href={Conf.apiUrl+'demo/suppliques-demo-'+doc.path+'/suppliques.zip'} target="_blank" rel="noreferrer">Lien vers le zip</a></p>}
    </div> : null}
  </> : '';
}

export default Demo;
