import React, {useContext,useCallback} from "react";
import "./Sons.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Fader from 'components/form/Fader';
import SonsList from 'components/Sons/SonsList';
import SonsTab from 'components/Sons/SonsTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
        <div className='spacer'></div>
        <div>{children}</div>
        </>
      )}
    </div>
  );
}

function Sons() {
  const { getCollection } = useContext(ModelContext);
  const { appState, setAppState, settings } = useContext(SettingsContext);
  const sons=getCollection('son').sort((a,b)=>a.titre.localeCompare(b.titre));
  const a11yProps=(index)=>{
    return {
      id: `sons-tab-${index}`,
      'aria-controls': `sons-tabpanel-${index}`,
    };
  }
  const handleTabChange=useCallback((v)=>{
    setAppState((state)=>{return {...state, tabs: {...state.tabs, sons:v}}});
  },[setAppState]);
  const currentTab=appState.tabs.sons;

  return <>
  <Container maxWidth="lg">
    <Stack direction="row" alignItems="center" justifyContent="space-between" divider={<span>&nbsp;&nbsp;</span>}>
      <Typography variant="h1" component="h1">
        Sons
      </Typography>
      <Fader name='Attenuation master (dB)' docId={settings._id} path={['sons','mainVolume']}/>
    </Stack>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={currentTab} onChange={(e,v)=>handleTabChange(v)} aria-label="basic tabs example">
        <Tab label="Tous les sons" {...a11yProps(0)} />
        <Tab label="Sons par déclencheur" {...a11yProps(1)} />
      </Tabs>
    </Box>
  </Container>
  <Container maxWidth="lg">
    <TabPanel value={currentTab} index={0}>
      <SonsList sons={sons}/>
    </TabPanel>
  </Container>
  <TabPanel value={currentTab} index={1} className="table-container">
    <SonsTab sons={sons}/>
  </TabPanel>
  <div className="big-spacer"/>
  </>
}

export default Sons;
