import React, {useContext,useState} from "react";
import "./Interlocuteurs.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Interlocuteur from 'components/Interlocuteur';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Sortable from 'components/Sortable';

function Interlocuteurs({portraitId}) {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const [ expanded,setExpanded ] = useState(null);
  const interlocuteurs=getCollection('interlocuteur').filter((o)=>o.portraitId===portraitId);
  const handleAddInterlocuteur=()=>{
    if (portraitId) {
      let interlocuteur=newItem('interlocuteur');
      interlocuteur.portraitId=portraitId;
      save(interlocuteur);
    }
  }
  const handleDeleteInterlocuteur=(interlocuteur,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer l\'interlocuteur ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer l\'interlocuteur ?'
    }).then((ok)=>{
      if (ok) remove(interlocuteur);
      else console.log('canceled');
    });
  }
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };
  return <>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddInterlocuteur}>
        Nouvel interlocuteur
      </Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Sortable list={interlocuteurs} render={(interlocuteur)=><Accordion
            key={interlocuteur._id}
            expanded={expanded === interlocuteur._id}
            onChange={handleExpandedChange(interlocuteur._id)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className='sort-handle'
            >
              <Typography>{(interlocuteur.nom || 'Sans nom') + ' '}
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={(e)=>handleDeleteInterlocuteur(interlocuteur,e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Interlocuteur id={interlocuteur._id}/>
            </AccordionDetails>
          </Accordion>
        }/>
        </Grid>
      </Grid>
  </>
}

export default Interlocuteurs;
