import React, {useContext,useState,useEffect} from "react";
import "./Sortable.scss";
import { ModelContext } from "providers/ModelProvider";
import { ReactSortable } from "react-sortablejs";

function Sortable({list,render,wrapper=null}) {
  const { doSaveBulk } = useContext(ModelContext);
  const [state, setState] = useState([]);
  useEffect(()=>{
    setState(list);
  },[list]);
  const setList = (newList) => {
    setState(newList);
    let changed=false;
    const sorts=list.map((o)=>o.sort);
    if (sorts.length>1) {
      for(let is=1;is<sorts.length;is++) {
        if (sorts[is-1]>=sorts[is]) {
          console.log('sort issue');
          sorts[is]=sorts[is-1]+1;
        }
      }
    }
    const res=[];
    newList.forEach((item, i) => {
      if (item.sort!==sorts[i]) {
        changed=true;
      }
      res.push({...item,sort:sorts[i]});
    });
    if (changed) {
      doSaveBulk(res);
    }
  };
  return <ReactSortable tag={wrapper} list={state} setList={setList} handle='.sort-handle'>
    {state.map((o)=>{return {...o,id:o._id}}).map((item) => render(item))}
  </ReactSortable>;
}

export default Sortable;
