import React, {useContext} from "react";
import "./Interface.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import TextLong from 'components/form/TextLong';

function Interface({ id }) {
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='interface' ? <>
    <Text name='Id' docId={id} path={['slug']}/>
    <Text name='Texte court' docId={id} path={['texte_court']}/>
    <TextLong name='Texte long' docId={id} path={['texte_long']}/>
  </> : '';
}

export default Interface;
