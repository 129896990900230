import React, {useContext} from "react";
import "./Application.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Text from 'components/form/Text';
import Upload from 'components/form/Upload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MarkDown from 'components/form/MarkDown';
import Grid from '@mui/material/Grid';
import { menuPages } from 'shared/Utils';

function Application() {
  const { getCollection } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const docCollection=getCollection('application');
  const doc=docCollection.length>0 ? docCollection[0] : null;
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,appExpanded:isExpanded ? id : null}});
  };
  return doc ? <Container maxWidth="md">
    <Typography variant="h1" component="h2">
      Application
    </Typography>
    <Typography variant="h2" component="h2">
      {doc.titre || 'Sans titre'}
    </Typography>
    <div className='spacer'></div>
    <Text name='Titre' docId={doc._id} path={['titre']}/>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Upload name='App logo' docId={doc._id} path={['logo']} maxWidth="300px" shadow={false}/>
      </Grid>
      <Grid item xs={12} md={6}>
        <Upload name='App background' docId={doc._id} path={['background']} maxWidth="300px"/>
      </Grid>
      <Grid item xs={12} md={6}>
        <Upload name='App animation' docId={doc._id} path={['animation']} type="zip"/>
      </Grid>
      <Grid item xs={12} md={6}>
        <Upload name='App splashscreen' docId={doc._id} path={['splashscreen']} type="image"/>
      </Grid>
    </Grid>
    <div className='spacer'></div>
    {menuPages.map((o)=><Accordion
      key={o}
      expanded={appState.appExpanded === o ? true : false}
      onChange={handleExpandedChange(o)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{doc[o+'_titre'] || '(sans id)'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {o!=='chronologie' ? <>
            <Text name='Titre' docId={doc._id} path={[o+'_titre']}/>
            {o!=='historique' && o!=='glossaire' && <>
              <MarkDown name='Texte' className={"mkdwn-"+o} docId={doc._id} path={[o+'_texte']} background='black' images={doc[o+'_images']||[]}/>
              <div className='spacer'></div>
              <Upload name='Images' docId={doc._id} path={[o+'_images']} maxWidth="300px" maxFiles={10}/>
            </>}
            {o==='historique' && <>
              <MarkDown name='Texte conversations' className={"mkdwn-"+o} docId={doc._id} path={[o+'_texte_conversations']} background='black' images={[]}/>
            </>}
          </>
          :
          <>
            <Text name='Titre' docId={doc._id} path={[o+'_titre']}/>
            <Upload name='Chronologie' docId={doc._id} path={['chronologie']} maxWidth="300px"/>
          </>
          }
        </AccordionDetails>
      </Accordion>)}
      <div className="big-spacer"/>
  </Container> : null;
}

export default Application;
