import React, {useContext} from "react";
import "./Sons.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";

const itemPerPage=12;

function SonsList({sons}) {
  const { newItem, save, remove } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const handleAddSon=()=>{
    let son=newItem('son');
    save(son);
  }
  const handleDeleteSon=(son,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le son ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le son ?'
    }).then((ok)=>{
      if (ok) remove(son);
      else console.log('canceled');
    });
  }
  const handleFiltreChange=(v)=>{
    setAppState((state)=>{return {...state, filtres: {...state.filtres, sons:v}, paginations: {...state.paginations, sons:1}}});
  };
  const handleChange=(v)=>handleFiltreChange(v.toLowerCase());
  const handlePageChange=(v)=>{
    setAppState((state)=>{return {...state, paginations: {...state.paginations, sons:v}}});
  };
  const filtre=appState.filtres.sons;
  const filteredSons=sons.filter((o)=>o.titre.toLowerCase().indexOf(filtre)!==-1);
  const nbPages=Math.ceil(filteredSons.length/itemPerPage);
  const currentPage=appState.paginations.sons;
  return <>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddSon}>
      Nouveau Son
    </Button>
    <div className='spacer'></div>
    <div>
      <TextField
      label='filtre'
      variant="outlined"
      value={filtre}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
      }}/>
    </div>
    <div className='spacer'></div>
    {nbPages>1 && <Pagination showFirstButton showLastButton count={nbPages} page={currentPage} onChange={(e,v)=>handlePageChange(v)} />}
    <div className='spacer'></div>
    <Grid container spacing={2}>
      {filteredSons.map((son,i)=>(i>=(currentPage-1)*itemPerPage && i<(currentPage)*itemPerPage) ? <Grid key={son._id} item xs={12} sm={6} md={4}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h4" component="h4" gutterBottom>
              {son.titre || 'Sans titre'}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={'/son/'+son._id}><Button size="small">Modifier</Button></Link>
            <Button size="small" onClick={(e)=>handleDeleteSon(son,e)}>Supprimer</Button>
          </CardActions>
        </Card>
      </Grid>:null)}
    </Grid>
    <div className='spacer'></div>
    {nbPages>1 && <Pagination showFirstButton showLastButton count={nbPages} page={currentPage} onChange={(e,v)=>handlePageChange(v)} />}
  </>
}

export default SonsList;
