import React, {useContext, useEffect, useMemo} from "react";
import "./Portrait.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Conversations from 'components/Conversations';
import Interlocuteurs from 'components/Interlocuteurs';
import Lettres from 'components/Lettres';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { useParams } from "react-router-dom";
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import TextLong from 'components/form/TextLong';
import Select from 'components/form/Select';
import Upload from 'components/form/Upload';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Player from './Player';
import Case from 'components/form/Case';

function Portrait() {
  const { id } = useParams();
  const { getDoc, getCollection } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  useEffect(()=>{
    if (appState && !appState.portraitsTabs) {
      setAppState((state)=>{return {...state,portraitsTabs:{[id]:0}}});
    }
  },[appState,setAppState,id])
  const doc=useMemo(()=>getDoc(id),[getDoc,id]);
  const themes=useMemo(()=>getCollection('theme').map((o)=>{return {label:o.titre || 'Sans nom',value:o._id}}),[getCollection]);
  const handleTabChange=(e,idx)=>{
    setAppState((state)=>{ return {...state,portraitsTabs:{...state.portraitsTabs,[id]:idx}}});
  }
  let tabIdx=0;
  if (appState.portraitsTabs &&  appState.portraitsTabs[id]) tabIdx=appState.portraitsTabs[id];
  return doc && doc.type==='portrait' ? <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h2">
        Portrait
      </Typography>
      <Typography variant="h2" component="h2">
        {doc.nom || 'Sans nom'}
      </Typography>
      <div className='spacer'></div>
      <Tabs value={tabIdx} onChange={handleTabChange}>
        <Tab label="Infos"/>
        <Tab label="Conversations"/>
        <Tab label="Interlocuteurs"/>
        <Tab label="Lettres"/>
        <Tab label="Que sont-ils devenus"/>
      </Tabs>
      <div className='spacer'></div>
      {tabIdx===0 && <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Text name='Nom' docId={id} path={['nom']}/>
          <TextLong name='Chapeau' docId={id} path={['chapeau']}/>
          <Select name='Thème' docId={id} path={['themeId']} options={[{label:'Sans thème',value:-1},...themes]}/>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Upload name='Photo' docId={id} path={['photo']}/>
        </Grid>
      </Grid>}
      {tabIdx===1 && <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Case name='Fond bleu (administration)' docId={id} path={['administration']}/>
          <Conversations portraitId={id}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Player portraitId={id} step={0}/>
        </Grid>
      </Grid>}
      {tabIdx===2 && <Grid container spacing={3}>
        <Grid item xs={12}>
          <Interlocuteurs portraitId={id}/>
        </Grid>
      </Grid>}
      {tabIdx===3 && <Grid container spacing={3}>
        <Grid item xs={12}>
          <Lettres portraitId={id}/>
        </Grid>
      </Grid>}
      {tabIdx===4 && <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text name='Titre' docId={id} path={['devenuTitre']}/>
          <MarkDown name='Texte' docId={id} path={['devenuTexte']}/>
        </Grid>
        <Grid item xs={6}>
          <Upload name='Photos' docId={id} path={['devenuImages']} maxFiles={10}/></Grid>
        </Grid>}
    </Container>
    <div className="big-spacer"/>
  </> : '';
}

export default Portrait;
