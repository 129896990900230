import React, {useContext,useEffect} from "react";
import "./Messages.scss";
import Typography from '@mui/material/Typography';
import Message from 'components/Message';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { confirm } from 'components/Confirm'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { initiales } from 'shared/conversationsUtils';
import Sortable from 'components/Sortable';

function Messages({conversationId}) {
  const { getDoc,getCollection, newItem, save, remove } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  useEffect(()=>{
    //console.log('init');
    if (appState && !appState.messagesExpanded) {
      setAppState((state)=>{return {...state,messagesExpanded:{[conversationId]:null}}});
    }
  },[appState,setAppState,conversationId])
  const handleExpandedChange=(id) => (event, isExpanded) => {

    setAppState((state)=>{return {...state,messagesExpanded:{...state.messagesExpanded,[conversationId]: isExpanded ? id : null}}});
  };
  const messages=getCollection('message').filter((o)=>o.conversationId===conversationId);
  const handleAddMessage=()=>{
    if (conversationId) {
      const c=getDoc(conversationId);
      let message=newItem('message');
      message.msgType='default';
      message.conversationId=conversationId;
      message.from=messages[messages.length-1] ? messages[messages.length-1].from : c.portraitId;
      message.heure=messages[messages.length-1] ? messages[messages.length-1].heure : '0:00';
      save(message);
    }
  }
  const handleDeleteMessage=(message,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le message ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le message ?'
    }).then((ok)=>{
      if (ok) remove(message);
      else console.log('canceled');
    });
  }
  return <>
    <Sortable list={messages} render={(message)=>{
      const from=getDoc(message.from);
      return <Accordion
      key={message._id}
      expanded={appState.messagesExpanded && appState.messagesExpanded[conversationId] === message._id ? true : false}
      onChange={handleExpandedChange(message._id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className='sort-handle'
        >
          <Typography>{message.heure} {from && initiales(from.nom)} - {message.options.default.text.substring(0,20)}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteMessage(message,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Message id={message._id}/>
        </AccordionDetails>
      </Accordion>
    }}/>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddMessage}>
      Nouveau message
    </Button>
  </>
}

export default Messages;
