import React, { useState, createContext, useContext, useEffect, useCallback, useMemo, useRef } from 'react';
import './MixProvider.scss';
import Conf from 'Conf';
import Mixer from './Mixer';
import { ModelContext } from "providers/ModelProvider";

export const MixContext = createContext({});

const players={};
const files=[];

let context={id:null};

const MixProvider = ({children}) => {
  const { getCollection } = useContext(ModelContext);
  const mixer=useRef(new Mixer({unlock:()=>{console.log('need Unlock !!')}}));
  const [ mixerOk, setMixerOk ] = useState(false);
  const [ muteAll, setMuteAll ] = useState(false);
  const [ volume, setVolume ] = useState(0);
  const sons=useMemo(()=>getCollection('son').map((o)=>{return {...o,src:o.fichier && o.fichier[0] ? Conf.filesUrl+o.fichier[0].url : null}}),[getCollection]);
  const settings=useMemo(()=>getCollection('settings'),[getCollection]);
  const conversations=useMemo(()=>getCollection('conversation'),[getCollection]);
  const messages=useMemo(()=>getCollection('message'),[getCollection]);
  const mute=()=>{
    mixer.current.mute();
  }
  const unMute=()=>{
    mixer.current.unMute();
  }
  useEffect(()=>{
    if (mixerOk) {
      window.addEventListener('blur',mute);
      window.addEventListener('focus',unMute);
      return ()=>{
        window.removeEventListener('blur',mute);
        window.removeEventListener('focus',unMute);
      }
    }
  },[mixerOk]);
  const triggerSon=useCallback((hook)=>{
    //console.log('triggerSon',hook);
    const toTrigger=[hook];
    const tab=hook.split('/');
    if (tab[0]==='lettre' && tab.length===2) {
      toTrigger.push('lettre');
    }
    if (tab[0]==='theme' && tab.length===2) {
      toTrigger.push('theme');
    }
    if (tab[0]==='conversation' && tab.length===2) {
      const conversation=conversations.find((o)=>o._id===tab[1]);
      toTrigger.push('portrait/'+conversation.portraitId+'/conversation');
      toTrigger.push('conversation');
    }
    if (tab[0]==='message' && tab.length===2) {
      const message=messages.find((o)=>o._id===tab[1]);
      const conversation=conversations.find((o)=>message && o._id===message.conversationId);
      if (conversation) {
        toTrigger.push('conversation/'+conversation._id+'/message');
        toTrigger.push('portrait/'+conversation.portraitId+'/message');
        toTrigger.push('message');
      }
    }
    sons.forEach((son, i) => {
      son.listeners.forEach((listener, i) => {
        if(son.fichier[0] && toTrigger.indexOf(listener.hook)!==-1) {
          const [src,track,type,hook] = [son.src,listener.track,son.loop ? 'loop' : (listener.action==='play out' ? 'play out' : 'regular'),listener.hook];
          //console.log(src,track,playOut,hook,listener.action);
          if (listener.action==='play' || listener.action==='play out') {
            mixer.current.play(src,track,type,hook);
          }
          if (listener.action==='pause') {
            mixer.current.pause(src,track,type,hook);
          }
          if (listener.action==='stop') {
            mixer.current.stop(src,track,type,hook);
          }
          if (listener.action==='play cut') {
            mixer.current.playCut(src,track,type,hook);
          }
          if (listener.action==='pause cut') {
            mixer.current.pauseCut(src,track,type,hook);
          }
          if (listener.action==='stop cut') {
            mixer.current.stopCut(src,track,type,hook);
          }
        }
      });
    });
  },[sons,conversations,messages]);
  const getContext=useCallback((contextId)=>{
    //console.log('getContext',contextId);
    const [ type, id ] = contextId.split('/');
    if (contextId!==context.id) {
      context={id:contextId,sons:[]};
      if (type==='animation') {
        sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              let test=false;
              if (listener.hook.startsWith('animation')) test=true;
              if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
            }
          });
        });
      }
      if (type==='landing') {
        sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              let test=false;
              if (listener.hook.startsWith('landing')) test=true;
              if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
            }
          });
        });
      }
      if (type==='portrait') {
        sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              const tab=listener.hook.split('/');
              let test=false;
              if (listener.hook==='portrait') test=true;
              if (tab[0]==='portrait' && tab[1]===id) test=true;
              if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
            }
          });
        });
        conversations.filter((o)=>o.portraitId===id).forEach((conversation) => {
          const conversationMessages=messages.filter((o)=>o.conversationId===conversation._id);
          sons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              if (listener.hook && son.fichier[0]) {
                const tab=listener.hook.split('/');
                let test=false;
                if (listener.hook==='message') test=true;
                if (listener.hook==='conversation') test=true;
                if (tab[0]==='conversation' && conversation._id===tab[1]) test=true;
                if (tab[0]==='message' && conversationMessages.findIndex((o)=>o._id===tab[1])!==-1) test=true;
                if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
              }
            });
          });
        });
      }
      if (type==='themes') {
        sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              const tab=listener.hook.split('/');
              let test=false;
              if (listener.hook==='themes') test=true;
              if (listener.hook==='theme') test=true;
              if (tab[0]==='theme') test=true;
              if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
            }
          });
        });
      }
      if (type==='lettre') {
        sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              const tab=listener.hook.split('/');
              let test=false;
              if (listener.hook==='lettre') test=true;
              if (['lettre','lettrePlay','lettrePause','lettreStop'].indexOf(tab[0])!==-1 && id===tab[1]) test=true;
              if (test) context.sons.push({...son,track:listener.track,url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null});
            }
          });
        });
      }
    }
    return context;
  },[sons,conversations,messages]);
  useEffect(()=>{
    if (mixerOk){
      if (muteAll) {
        mixer.current.muteAll();
      } else {
        mixer.current.unMuteAll(volume);
      }
    }
  },[muteAll,volume,mixerOk]);
  useEffect(()=>{
    if (mixerOk && settings[0] && settings[0].sons && settings[0].sons.tracksVolume) {
      mixer.current.setVolume(settings[0].sons.mainVolume);
      Object.keys(settings[0].sons.tracksVolume).forEach((track) => {
        mixer.current.setTrackVolume(track,settings[0].sons.tracksVolume[track]);
      });
    }
  },[settings,setVolume,mixerOk]);
  const initMixer=useCallback(()=>{
    mixer.current.init(sons.map((son)=>{return{
      url:son.fichier && son.fichier[0] ? Conf.filesUrl+son.fichier[0].url : null,
      listeners:son.listeners,
      volume:son.volume,
      loop:son.loop,
      crossFadeDuration:son.crossFadeDuration,
    }}));
    setMixerOk(true);
    document.body.removeEventListener('click',initMixer);
  },[setMixerOk,sons]);
  useEffect(()=>{
    document.body.addEventListener('click',initMixer);
    return ()=>{
      document.body.removeEventListener('click',initMixer);
    }
  },[sons,initMixer]);
  const clearContext=useCallback((contextId)=>{
    const context=getContext(contextId);
    const toStop=[];
    context.sons.forEach((son) => {
      son.listeners.forEach((listener) => {
        const [src,track,type] = [son.src,listener.track,son.loop ? 'loop' : (listener.action==='play out' ? 'play out' : 'regular')];
        const p=toStop.findIndex((o)=>o.src===src && o.track===track && o.type===type);
        if (p===-1) {
          //console.log(src,track,playOut);
          mixer.current.stop(src,track,type);
          toStop.push({src,track,type});
        }
      });
    });
    //console.log('clear context',contextId);
  },[getContext]);
  const mixerOn=useCallback(({hook, eventName, cb})=>{
    //console.log({hook, eventName, cb});
    let [url,track,type]=[null,null,null];
    sons.forEach((son)=>{
      son.listeners.forEach((listener) => {
        if (listener.hook===hook) {
          url=son.src;
          track=listener.track;
          type=son.loop ? 'loop' : (listener.action==='play out' ? 'play out' : 'regular');
          //console.log({url,track});
        }
      });
    });
    if (url && track && type) mixer.current.on({url,track,type,eventName,cb});
  },[sons])
  const mixerOff=useCallback(({hook, eventName, cb})=>{
    let [url,track,type]=[null,null,null];
    sons.forEach((son)=>{
      son.listeners.forEach((listener) => {
        if (listener.hook===hook) {
          url=son.src;
          track=listener.track;
          type=son.loop ? 'loop' : (listener.action==='play out' ? 'play out' : 'regular');
        }
      });
    });
    if (url && track && type) mixer.current.off({url,track,type,eventName,cb});
  },[sons])
  const setCurrentTime=useCallback(({hook, p})=>{
    let [url,track,type]=[null,null,null];
    sons.forEach((son)=>{
      son.listeners.forEach((listener) => {
        if (listener.hook===hook) {
          url=son.src;
          track=listener.track;
          type=son.loop ? 'loop' : (listener.action==='play out' ? 'play out' : 'regular');
        }
      });
    });
    //console.log({hook, p, url, track});
    if (url && track && type) mixer.current.setCurrentTime({url,track,type,p});
  },[sons])
  return <MixContext.Provider value={{ mixerOk, setCurrentTime, mixerOn, mixerOff, clearContext, files, triggerSon, muteAll, setMuteAll, sons, players}}>
    {children}
  </MixContext.Provider>;
}
export default MixProvider;
