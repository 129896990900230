import React, {useContext,useMemo} from "react";
import "./AppMessage.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import Upload from 'components/form/Upload';
import Select from 'components/form/Select';

const types=[
  {label:'Avertissement',value:'avertissement'},
  {label:'Conseil',value:'conseil'},
]
const displayTypes=[
  {label:'Une fois par visite',value:'state'},
  {label:'Une fois à la première visite seulement',value:'localstorage'},
]

function AppMessage({ id }) {
  const { getDoc,messagesHooks } = useContext(ModelContext);
  const doc=useMemo(()=>getDoc(id),[getDoc,id]);
  return doc && doc.type==='appMessage' ? <>
    <Select name='Evenements' docId={id} path={['hooks']} multiple options={messagesHooks}/>
    <Select name='Type' docId={id} path={['msgType']} options={types}/>
    <Select name='Affichage' docId={id} path={['display']} options={displayTypes}/>
    <Text name='Titre' docId={id} path={['titre']}/>
    <MarkDown name='Texte' docId={id} path={['texte']}/>
    <Upload name='Images' docId={id} path={['images']} maxWidth="300px" maxFiles={10}/>
    <Text type='number' name='Durée' docId={id} path={['duree']}/>
  </> : '';
}

export default AppMessage;
