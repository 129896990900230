const Conf={
  dbUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"db/model/" : "https://suppliques-back.lefil.org/db/model",
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://suppliques-back.lefil.org/",
  uploadUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"upload/" : "https://suppliques-back.lefil.org/upload/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://suppliques-back.lefil.org/files/",
  renewInterval:24*60*60*1000,
  msgType:[
    {value:'default',label:'Normal'},
    {value:'missed',label:'Appel manqué'},
    {value:'empty',label:'Vide'},
  ],
  skels:{
    theme:{
      type:'theme',
      titre:''
    },
    portrait:{
      type:'portrait',
      nom:'',
      photo:[],
      chapeau:'',
      themeId:null,
      administration:false,
      devenuTitre:'',
      devenuTexte:'',
      devenuImages:[],
    },
    conversation:{
      type:'conversation',
      date:'',
      messages:[],
      protagoniste:null,
      portraitId:null,
    },
    interlocuteur:{
      type:'interlocuteur',
      nom:'',
      presentation:'',
      prenom:'',
      photo:[],
      portraitId:null,
    },
    message:{
      type:'message',
      heure:'',
      from:null,
      msgType:null,
      options:{
        default:{
          text:'',
          failed:false,
        },
        missed:{
          to:'',
        }
      },
      conversationId:null,
    },
    appMessage:{
      type:'appMessage',
      msgType:'avertissement',
      hooks:[],
      display:'state',
      texte:'',
      titre:'',
      duree:2000,
    },
    lien:{
      type:'lien',
      slug:'',
      titre:'',
      texte:'',
      images:[],
    },
    son:{
      type:'son',
      titre:'',
      texte:'',
      loop:false,
      fadeIn:false,
      volume:1,
      fichier:[],
      listeners:[],
    },
    listener: {
      type:'listener',
      action:'play out',
      hook:null,
      track:'track-0',
    },
    interface:{
      type:'interface',
      slug:'',
      texte_court:'',
      texte_long:'',
    },
    lettre:{
      type:'lettre',
      date:'',
      from:'',
      to:'',
      scan:[],
      vignette:[],
      portraitId:null,
      tapuscrit:'',
      scale:1/30,
      x:0,
      y:0,
    },
    settings:{
      type:'settings',
      sons:{
        mainVolume:1,
        tracksVolume:{
          'track-0':1,
          'track-1':1,
          'track-2':1,
          'track-3':1,
          'track-4':1,
          'track-5':1,
          'track-6':1,
          'track-7':1,
          'track-8':1,
          'track-9':1,
          'track-10':1,
          'track-11':1,
          'track-12':1,
          'track-13':1,
          'track-14':1,
          'track-15':1,
          'track-16':1,
          'track-17':1,
          'track-18':1,
          'track-19':1,
        },
      },
    },
    demo:{
      type:'demo',
      nom:'',
      path:'',
      commit:'',
      portraits:[],
      date:'',
    }
  }
}
export default Conf;
