import React, { useContext, useState, useEffect, useRef } from "react";
import "./TextLong.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

function TextLong({docId,path,name='',parse=(v)=>null}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path) || '';
  const [ value,setValue ] = useState(dbValue);
  const updateTimer=useRef(null);
  const saveTimer=useRef(null);
  const parseTimer=useRef(null);
  const handleChange=(v)=>{
    setValue(v);
    if (updateTimer.current) clearTimeout(updateTimer.current);
    if (parseTimer.current) clearTimeout(parseTimer.current);
    parseTimer.current=setTimeout(()=>{
      parse(v);
    },3000);
    if (saveTimer.current) clearTimeout(saveTimer.current);
    saveTimer.current=setTimeout(()=>{
      savePath(docId,path,v);
    },1000);
  };
  useEffect(()=>{
    if (updateTimer.current) clearTimeout(updateTimer.current);
    updateTimer.current=setTimeout(()=>{
      setValue(dbValue);
      console.log('update!')
    },2000);
  },[dbValue,setValue]);
  const saved= dbValue===value;
  return (
    <div className="input-text-long">
      <TextField
      multiline
      minRows={3}
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end"><div style={{width:'30px'}}>{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</div></InputAdornment>,
      }}/>
    </div>
  )
}

export default TextLong;
