import React, {useContext,useState} from "react";
import "./Lettres.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Lettre from 'components/Lettre';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Sortable from 'components/Sortable';

function Lettres({portraitId}) {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const [ expanded,setExpanded ] = useState(null);
  const lettres=getCollection('lettre').filter((o)=>o.portraitId===portraitId);
  const handleAddLettre=()=>{
    if (portraitId) {
      let lettre=newItem('lettre');
      lettre.portraitId=portraitId;
      save(lettre);
    }
  }
  const handleDeleteLettre=(lettre,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer la lettre ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer la lettre ?'
    }).then((ok)=>{
      if (ok) remove(lettre);
      else console.log('canceled');
    });
  }
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };
  return <>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddLettre}>
        Nouvelle lettre
      </Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Sortable list={lettres} render={(lettre)=><Accordion
            key={lettre._id}
            expanded={expanded === lettre._id}
            onChange={handleExpandedChange(lettre._id)}
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className='sort-handle'
            >
              <Typography>{(lettre.date || 'Sans date') + ' '}
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={(e)=>handleDeleteLettre(lettre,e)}
                >
                  <DeleteIcon />
                </IconButton>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Lettre id={lettre._id}/>
            </AccordionDetails>
          </Accordion>
          }/>
        </Grid>
      </Grid>
  </>
}

export default Lettres;
