import React, { useContext, useState, useMemo, useEffect } from "react";
import "./Heure.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import * as _ from 'lodash';
import TimePicker from '@mui/lab/TimePicker';
import { format,parse,isValid } from 'date-fns'

function Heure({docId,path,name=''}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const handleChange=(d)=>{
    if (isValid(d)) {
      const v=format(d,'H:mm')
      setValue(v);
      save(v);
    }
  };
  useEffect(()=>{
    setValue(dbValue)
  },[dbValue]);
  const save=useMemo(()=>_.debounce((v)=>{
    savePath(docId,path,v);
  },500),[docId,path,savePath]);
  const saved= dbValue===value;
  return (
    <div className="input-text">
      <TimePicker
        label={name}
        value={parse(value, 'H:mm', new Date())}
        onChange={handleChange}
        renderInput={(params) => <TextField
          fullWidth
          {...params}
          InputProps={{
            endAdornment: <>
            {params.InputProps.endAdornment} <span>&nbsp;</span>
            <InputAdornment position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>
            </>
          }}
        />}
      />
    </div>
  )
}

export default Heure;
