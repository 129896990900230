import React, {useContext} from "react";
import "./Topbar.scss";
import { AuthContext } from "providers/AuthProvider";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  Link,
} from "react-router-dom";

function Topbar() {
  const { auth, onLoggedOut } = useContext(AuthContext);
  return (
    <AppBar position="static" className="topbar">
        <Toolbar>
          <Typography variant="h6" component="div" className="topbar-item">
            <Link to="/application">App</Link>
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            <Link to="/themes">Thèmes</Link>
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            <Link to="/portraits">Portraits</Link>
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            <Link to="/liens">Liens</Link>
          </Typography>
          <Typography variant="h6" component="div" className="topbar-item">
            <Link to="/sons">Sons</Link>
          </Typography>
          <Typography variant="h6" component="div"className="topbar-item">
            <Link to="/interface">Interface</Link>
          </Typography>
          <Typography variant="h6" component="div"className="topbar-item">
            <Link to="/app-messages">Messages</Link>
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="topbar-item">
            <Link to="/publier">Publier</Link>
          </Typography>
          <small>{auth.username}</small>
          <Button color="inherit" onClick={onLoggedOut}>Logout</Button>
        </Toolbar>
      </AppBar>
  )
}

export default Topbar;
