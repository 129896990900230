import React, {useContext,useState,useMemo} from "react";
import "./Lettre.scss";
import Upload from 'components/form/Upload';
import { ModelContext } from "providers/ModelProvider";
import { MixContext } from "shared/providers/MixProvider";
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import Grid from '@mui/material/Grid';
import LettreInner from "shared/components/Lettre";
import IconButton from '@mui/material/IconButton';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

function Lettre({ id }) {
  const { getDoc, save } = useContext(ModelContext);
  const { muteAll, setMuteAll } = useContext(MixContext);
  const [ params, setParams] = useState({});
  const doc=getDoc(id);
  const paramsDb=useMemo(()=>{
    if(doc) {
      const {x:xDoc,y:yDoc,scale:scaleDoc}=doc;
      return {x:xDoc,y:yDoc,scale:scaleDoc};
    }
    return {x:0,y:0,scale:5}
  },[doc]);
  const paramsNeedSave=(params.scale!==undefined && params.scale!==doc.scale)
  || (params.x!==undefined && params.x!==doc.x)
  || (params.y!==undefined && params.y!==doc.y);
  const handleSaveParams=()=>{
    save({...doc,...params});
  }
  const handleScaleChange=(s)=>{
    console.log(s);
    setParams((state)=>{return {...state,scale:s/3}});
  }
  const handlePositionChange=(x,y)=>{
    setParams((state)=>{return {...state,x,y}});
  }
  return doc && doc.type==='lettre' ? <Grid container spacing={2}>
  <Grid item xs={8}>
    <Text name='Date' docId={id} path={['date']}/>
    <Text name='De' docId={id} path={['from']}/>
    <Text name='À' docId={id} path={['to']}/>
    <Upload name='Scan' docId={id} path={['scan']}/>
    <Upload name='vignette' docId={id} path={['vignette']}/>
  </Grid>
  <Grid item xs={4}>
    <div className="lettre-container">
      <div className="lettre-container-inner">
        <LettreInner id={id} setScale={handleScaleChange} setPosition={handlePositionChange} position={paramsDb} scale={paramsDb.scale}/>
        <div className="player-tool-bar">
          <IconButton
            size="small"
            color="inherit"
            onClick={()=>setMuteAll(!muteAll)}
          >
            {muteAll ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
          {paramsNeedSave && <IconButton
            size="small"
            color="inherit"
            onClick={handleSaveParams}
          >
            <SaveAltIcon />
          </IconButton>}
        </div>
      </div>
    </div>
  </Grid>
  <Grid item xs={12}>
    <MarkDown name='Tapuscrit' docId={id} path={['tapuscrit']}/>
  </Grid>
</Grid> : null;
}

export default Lettre;
