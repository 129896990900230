import React, { useContext, useState, useEffect, useRef } from "react";
import "./Text.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

function Text({docId,path,name=''}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path) || '';
  const [ value,setValue ] = useState(dbValue);
  const saveTimer=useRef(null);
  const updateTimer=useRef(null);
  const handleChange=(v)=>{
    setValue(v);
    if (updateTimer.current) clearTimeout(updateTimer.current);
    if (saveTimer.current) clearTimeout(saveTimer.current);
    saveTimer.current=setTimeout(()=>{
      savePath(docId,path,v);
    },1000);
  };
  useEffect(()=>{
    if (updateTimer.current) clearTimeout(updateTimer.current);
    updateTimer.current=setTimeout(()=>{
      setValue(dbValue);
      console.log('update!')
    },3000);
  },[dbValue,setValue]);
  const saved= dbValue===value;
  return (
    <div className="input-text">
      <TextField
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>,
      }}/>
    </div>
  )
}

export default Text;
