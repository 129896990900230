import React, {useContext, useLayoutEffect} from "react";
import './App.css';
import 'shared/styles.scss';

import Login from 'components/Login';
import Application from 'components/Application';
import Interfaces from 'components/Interfaces';
import Publier from 'components/Publier';
import Themes from 'components/Themes';
import Theme from 'components/Theme';
import Portraits from 'components/Portraits';
import Portrait from 'components/Portrait';
import Liens from 'components/Liens';
import Lien from 'components/Lien';
import Son from 'components/Son';
import Sons from 'components/Sons';
import AppMessages from 'components/AppMessages';
import Topbar from 'components/Topbar';
import Uploads from 'components/Uploads';
import PlayerConf from 'shared/Conf';

import { AuthContext } from "providers/AuthProvider";
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";

function RequireAuth({ children }) {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

function App() {
  const { auth } = useContext(AuthContext);
  useLayoutEffect(()=>{
    const root = document.documentElement;
    PlayerConf.css.forEach(rule => root.style.setProperty('--'+rule.key, rule.value));
    root.style.setProperty('--pixelSize', "1px");
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
  	if (window.AudioContext) {
  		window.audioContext = new window.AudioContext();
  	}
  	var fixAudioContext = function (e) {
  		if (window.audioContext) {
  			// Create empty buffer
  			var buffer = window.audioContext.createBuffer(1, 1, 22050);
  			var source = window.audioContext.createBufferSource();
  			source.buffer = buffer;
  			// Connect to output (speakers)
  			source.connect(window.audioContext.destination);
  			// Play sound
  			if (source.start) {
  				source.start(0);
  			} else if (source.play) {
  				source.play(0);
  			} else if (source.noteOn) {
  				source.noteOn(0);
  			}
  		}
      console.log('fixed');
  		// Remove events
  		document.removeEventListener('touchstart', fixAudioContext);
  		document.removeEventListener('touchend', fixAudioContext);
  	};
  	// iOS 6-8
  	document.addEventListener('touchstart', fixAudioContext);
  	// iOS 9
  	document.addEventListener('touchend', fixAudioContext);
    return ()=>{
    	document.removeEventListener('touchstart', fixAudioContext);
    	// iOS 9
    	document.removeEventListener('touchend', fixAudioContext);
    }
  },[]);
  return (
    <>
      {auth.token && <Topbar/>}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/application" element={<RequireAuth><Application /></RequireAuth>} />
        <Route path="/themes" element={<RequireAuth><Themes /></RequireAuth>} />
        <Route path="/theme/:id" element={<RequireAuth><Theme /></RequireAuth>} />
        <Route path="/portraits" element={<RequireAuth><Portraits /></RequireAuth>} />
        <Route path="/portrait/:id" element={<RequireAuth><Portrait /></RequireAuth>} />
        <Route path="/liens" element={<RequireAuth><Liens /></RequireAuth>} />
        <Route path="/lien/:id" element={<RequireAuth><Lien /></RequireAuth>} />
        <Route path="/sons" element={<RequireAuth><Sons /></RequireAuth>} />
        <Route path="/son/:id" element={<RequireAuth><Son /></RequireAuth>} />
        <Route path="/interface" element={<RequireAuth><Interfaces /></RequireAuth>} />
        <Route path="/publier" element={<RequireAuth><Publier /></RequireAuth>} />
        <Route path="/app-messages" element={<RequireAuth><AppMessages /></RequireAuth>} />
        <Route path="*" element={<RequireAuth><Portraits /></RequireAuth>} />
        </Routes>
      {auth.token && <Uploads/>}
    </>
  );
}

export default App;
