import React, {useContext} from "react";
import "./Lien.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { useParams } from "react-router-dom";
import Text from 'components/form/Text';
import MarkDown from 'components/form/MarkDown';
import Upload from 'components/form/Upload';

function Lien() {
  const { id } = useParams();
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='lien' ? <>
  <Container maxWidth="lg">
    <Typography variant="h1" component="h2">
      Lien
    </Typography>
    <Typography variant="h2" component="h2">
      {doc.titre || 'Sans titre'} ({doc.slug})
    </Typography>
    <div className='spacer'></div>
    <Text name='Titre' docId={id} path={['titre']}/>
    <Text name='Id' docId={id} path={['slug']}/>
    <MarkDown name='Texte' docId={id} path={['texte']}/>
    <Upload name='Images' docId={id} path={['images']} maxFiles={4}/>
  </Container>
  </> : '';
}

export default Lien;
