import React, {useContext,useMemo} from "react";
import "./AppMessages.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import { SettingsContext } from "providers/SettingsProvider";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AppMessage from 'components/AppMessage';

function AppMessages() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const appMessages=useMemo(()=>getCollection('appMessage'),[getCollection]);
  const { appState, setAppState } = useContext(SettingsContext);
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,appMessagesExpanded:isExpanded ? id : null}});
  };
  const handleAddAppMessage=()=>{
    let appMessage=newItem('appMessage');
    save(appMessage);
  }
  const handleDeleteAppMessage=(text,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le message ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le message ?'
    }).then((ok)=>{
      if (ok) remove(text);
      else console.log('canceled');
    });
  }
  return <>
  <Container maxWidth="lg">
    <Typography variant="h1" component="h1">
      Messages de l'application
    </Typography>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddAppMessage}>
      Nouveau message
    </Button>
    <div className='spacer'></div>
    {appMessages.map((appMessage)=><Accordion
      key={appMessage._id}
      expanded={appState.appMessagesExpanded === appMessage._id ? true : false}
      onChange={handleExpandedChange(appMessage._id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{appMessage.titre || '(sans titre)'}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteAppMessage(appMessage,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AppMessage id={appMessage._id}/>
        </AccordionDetails>
      </Accordion>)}
    <div className="big-spacer"/>
  </Container>
  </>
}

export default AppMessages;
