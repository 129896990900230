import React, {useContext, useCallback, useEffect} from "react";
import "./Message.scss";
import { ModelContext } from "providers/ModelProvider";
import Heure from 'components/form/Heure';
import Case from 'components/form/Case';
import TextLong from 'components/form/TextLong';
import Select from 'components/form/Select';
import Conf from 'Conf';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";
let ask={};

function Message({ id }) {
  const { getDoc, newItem, getCollection, save } = useContext(ModelContext);
  const doc=getDoc(id);
  const conversation=doc ? getDoc(doc.conversationId) : null;
  const portrait=doc ? getDoc(conversation.portraitId) : null;
  const interlocuteurs=portrait ? [portrait,...getCollection('interlocuteur').filter((o)=>o.portraitId===portrait._id)].map((o)=>{return {label:o.nom || 'Sans nom',value:o._id}}) : null;
  const dbLinks=getCollection('lien');
  const parse=useCallback((text)=>{
    const links=[...text.matchAll(/\[([^>\]]+)>>([^\]]+)\]/gm)];
    if (links.length>0) {
      links.forEach((link)=>{
        let dbLink=dbLinks.find((o)=>o.slug===link[2]);
        if (!dbLink) {
          if (!ask[link[2]]) {
            ask[link[2]]=true;
            confirm({
              titre: 'Créer le lien ?',
              confirmation: <>Voulez-vous créer le lien avec l'id <strong>{link[2]}</strong></>
            }).then((ok)=>{
              if (ok) {
                let lien=newItem('lien');
                lien.slug=link[2];
                lien.titre=link[2];
                //console.log('linkcreated',link[2])
                save(lien);
                } else {
                //console.log('canceled');
              }
            });
          }
        } else {
          //console.log('link exists',link[2]);
        }
      });
    }
  },[dbLinks,save,newItem]);
  const text= doc?.options?.default?.text ? doc.options.default.text : '';
  const matches=[...text.matchAll(/\[([^>\]]+)>>([^\]]+)\]/gm)];
  const messageLiens=matches.map((o)=>o[2]).filter((id)=>dbLinks.findIndex((o)=>o.slug===id)!==-1);
  useEffect(()=>{
    if (doc?.options?.default?.text && doc.options.default.text.length>0) parse(doc.options.default.text);
  },[parse,doc]);
  return doc && doc.type==='message' ? <>
    <Heure name='Heure' docId={id} path={['heure']}/>
    <Select name='De' docId={id} path={['from']} options={interlocuteurs}/>
    <Select name='Type' docId={id} path={['msgType']} options={Conf.msgType}/>
    {doc.msgType==='default' && <>
      <TextLong name='Texte' docId={id} path={['options','default','text']} parse={parse}/>
      <Case name='Non distribué' docId={id} path={['options','default','failed']}/>
    </>}
    {messageLiens.map((id, i)=>{
      const lien=dbLinks.find((o)=>o.slug===id);
      return lien ? <span key={i}>{i>0 ? ', ':''}<Link to={'/lien/'+lien._id}>{id}</Link></span>: '';
    })}
    {doc.msgType==='missed' && <>
      <Select name='À' docId={id} path={['options','missed','to']} options={interlocuteurs}/>
    </>}
  </> : '';
}

export default Message;
