import React, {useContext} from "react";
import "./Demos.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import { SettingsContext } from "providers/SettingsProvider";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Demo from 'components/Demo';

function Demos() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const demos=getCollection('demo');
  const { appState, setAppState } = useContext(SettingsContext);
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,demosExpanded:isExpanded ? id : null}});
  };
  const handleAddDemo=()=>{
    let demo=newItem('demo');
    save(demo);
  }
  const handleDeleteDemo=(demo,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer la démo ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer la demo '+ demo.nom + ' ?'
    }).then((ok)=>{
      if (ok) remove(demo);
      else console.log('canceled');
    });
  }
  return <>
  <Container maxWidth="lg">
    <Typography variant="h1" component="h1">
      Démos
    </Typography>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddDemo}>
      Nouvelle demo
    </Button>
    <div className='spacer'></div>
      {demos.map((demo)=><Accordion
      key={demo._id}
      expanded={appState.demosExpanded === demo._id ? true : false}
      onChange={handleExpandedChange(demo._id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{demo.nom || '(sans nom)'}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteDemo(demo,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Demo id={demo._id}/>
        </AccordionDetails>
      </Accordion>)}
    <div className="big-spacer"/>
  </Container>
  </>
}

export default Demos;
