import React, {useContext,useEffect} from "react";
import "./Publier.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import IconButton from '@mui/material/IconButton';
import Demos from 'components/Demos';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTime } from "luxon";
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import Select from 'components/form/Select';
import Conf from 'Conf';

function Publier() {
  const { getCollection, publish, published, publishProd, publishedProd, gitLog, fetchGitLog } = useContext(ModelContext);
  const docCollection=getCollection('application');
  const doc=docCollection.length>0 ? docCollection[0] : null;
  const portraits=getCollection('portrait').map((o)=>{return {label:o.nom || 'Sans nom',value:o._id}});
  const currentCommit= gitLog && doc.commit ? gitLog.find((o)=>o.hash===doc.commit) : null;
  const options=gitLog ? [{label:'Dernier commit',value:-1},...gitLog.map((o)=>{
    return {
      label:DateTime.fromISO(o.date).toLocaleString()+' - '+o.message.substring(0,50),
      value:o.hash,
    }
  })] : null;
  const handleClick=()=>{
    confirm({
      titre: 'Publier en production ?',
      confirmation: 'Êtes vous sûr de vouloir publier en production ?'
    }).then((ok)=>{
      if (ok) publishProd(doc.portraits,doc.commit);
      else console.log('canceled');
    });
  }
  useEffect(()=>{
    if (!gitLog) {
      fetchGitLog();
    }
  },[fetchGitLog,gitLog])
  return <>
    {doc && <Container maxWidth="lg" className="publier">
      <Typography variant="h1" component="h1">
        Développement
      </Typography>
      <Button variant="contained" className="topbar-item publish" onClick={publish} disabled={!published}>
        Publier sur l'url de developpement {!published && <CircularProgress size={20}/>}
      </Button>
      <div className="spacer"/>
      <Typography variant="h1" component="h1">
        Production
      </Typography>
      {options && <Stack direction="row" spacing={2}>
        <div style={{flexGrow:1}}>
          <Select name='Commit' docId={doc._id} path={['commit']} options={options}/>
        </div>
        <div style={{display:'flex',alignItems:'center'}}>
        <IconButton
          size="small"
          color="inherit"
          onClick={fetchGitLog}
        >
          <RefreshIcon />
        </IconButton>
        </div>
      </Stack>}
      <Select name='Portraits' docId={doc._id} path={['portraits']} options={portraits} multiple/>
      <div className="spacer"/>
      <Button variant="contained" className="topbar-item publish" onClick={handleClick} disabled={!publishedProd}>
        Publier sur l'url production {!publishedProd && <CircularProgress size={20}/>}
      </Button>
      {currentCommit && <p>Commit :  {DateTime.fromISO(currentCommit.date).toLocaleString()+' - '+currentCommit.message.substring(0,50)}</p>}
      <p><a href={Conf.apiUrl+'prod/suppliques.zip'} target="_blank" rel="noreferrer">Lien vers le zip</a></p>
    </Container>}
    <Demos/>
  </>
}

export default Publier;
