import React, {useContext,useState,useMemo,useEffect,useCallback} from "react";
import "./Sons.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUp from '@mui/icons-material/VolumeUp';
import LoopIcon from '@mui/icons-material/Loop';
import {
  Link,
} from "react-router-dom";
import Fader from 'components/form/Fader';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import Container from '@mui/material/Container';
import Conf from 'Conf';
import axios from 'axios';
import Grid from '@mui/material/Grid';

function SonsTab({sons}) {
  const { audioHooks,contexts,auth } = useContext(ModelContext);
  const { settings,appState,setAppState } = useContext(SettingsContext);
  const [ selectedLine,setSelectedLine ] = useState(null);
  const [ fileSize,setFilesize ] = useState('');
  const [ sizeByFile,setSizeByFile ] = useState({});
  const context=useMemo(()=>appState.sonsContext || (contexts.length>0 ? contexts[0] : null),[appState,contexts]);
  const setContext=useCallback((v)=>{
    const c=contexts.find((o)=>o.value===v);
    if (c) setAppState(state=>{return {...state, sonsContext:c}})
  },[setAppState,contexts]);
  const actionIcon=(action)=>{
    if(action==='play out') return <VolumeUp className="action-icon"/>
    if(action==='play') return <PlayArrowIcon className="action-icon"/>
    if(action==='pause') return <PauseIcon className="action-icon"/>
    if(action==='stop') return <StopIcon className="action-icon"/>
    if(action==='play cut') return <><PlayArrowIcon className="action-icon"/>!</>
    if(action==='pause cut') return <><PauseIcon className="action-icon"/>!</>
    if(action==='stop cut') return <><StopIcon className="action-icon"/>!</>
    if(action==='loop') return <><LoopIcon className="action-icon"/></>
  }
  const contextHooks=useMemo(()=>{
    const c=contexts.find((o)=>o.value===context.value);
    return c ? c.hooks.filter((hook)=>{
      for(let i=0;i<sons.length;i++){
        const son=sons[i];
        for(let j=0;j<son.listeners.length;j++){
          const listener=son.listeners[j];
          if(listener.hook===hook.value) return true;
        }
      }
      return false;
    }) : [];
  },[contexts,context,sons]);
  const tracks=useMemo(()=>[...Array(20).keys()].filter((trackNumber)=>{
    for(let i=0;i<contextHooks.length;i++){
      const hook=contextHooks[i];
      for(let j=0;j<sons.length;j++){
        const son=sons[j];
        for(let k=0;k<son.listeners.length;k++){
          const listener=son.listeners[k];
          if(listener.hook===hook.value){
            if (listener.track==='track-'+(trackNumber)) {
              return true;
            }
          }
        }
      };
    }
    return false;
  }),[sons,contextHooks]);
  useEffect(()=>{
    const ids=[];
    for(let i=0;i<contextHooks.length;i++){
      const hook=contextHooks[i];
      for(let j=0;j<sons.length;j++){
        const son=sons[j];
        for(let k=0;k<son.listeners.length;k++){
          const listener=son.listeners[k];
          if(listener.hook===hook.value){
            if (son.fichier && son.fichier[0] && ids.indexOf(son.fichier[0].url)===-1) ids.push(son.fichier[0].url);
          }
        }
      };
    }
    axios.post(Conf.apiUrl+'filesize', {ids},{ headers: { Authorization: 'Bearer '+auth.token},responseType:'json'}).then((res)=>{
      const fileSizeSI=(a,b,c,d,e)=>{
       return (b=Math,c=b.log,d=1e3,e=c(a)/c(d)|0,a/b.pow(d,e)).toFixed(2)
       +' '+(e?'kMGTPEZY'[--e]+'B':'Bytes')
      }
      setFilesize(fileSizeSI(res.data.size));
      const o={};
      Object.keys(res.data.sizeByFile).forEach((id)=>o[id]=fileSizeSI(res.data.sizeByFile[id]));
      setSizeByFile(o);
    }).catch((error)=>{
      console.log(error);
    });
  },[sons,contextHooks,setFilesize,setSizeByFile,auth.token]);
  console.log(audioHooks,contexts,context)
  return <>
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel>Contexte</InputLabel>
            <SelectMui
            value={context.value}
            label={'Contexte'}
            onChange={(e)=>setContext(e.target.value)}
            >
              {contexts.map((option)=><MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
            </SelectMui>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <div className="total-size">{fileSize}</div>
        </Grid>
      </Grid>
    </Container>
    <div className="big-spacer"/>
    {contextHooks.length>0 && <Container maxWidth="xxl">
      <TableContainer>
        <Table stickyHeader aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell className="cell-hook">Event</TableCell>
              {tracks.map((idx)=><TableCell key={idx} className="cell-track">
                Piste {idx+1}
                <Fader name='Attenuation (dB)' docId={settings._id} path={['sons','tracksVolume','track-'+idx]}/>
              </TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {contextHooks.map((hook,ih)=>{
              const usedSons=(idx)=>sons.filter((son)=>{
                let test=false;
                son.listeners.forEach((listener, i) => {
                  if(listener.hook===hook.value && (idx===-1 || listener.track==='track-'+idx)) test=true;
                });
                return test;
              });
              return usedSons(-1).length>0 ? <TableRow
              selected={selectedLine===hook.value}
              onClick={()=>setSelectedLine(selectedLine===hook.value ? null : hook.value)}
              key={hook.value}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell key={'label'}>{hook.label}</TableCell>
                {tracks.map((idx)=>
                  <TableCell key={'track-'+idx}>{usedSons(idx).map((son)=><div key={son._id} className="son-cell"><Link to={'/son/'+son._id}>{son.titre}</Link><span className="son-size">{sizeByFile[son.fichier && son.fichier[0] ? son.fichier[0].url : null]} {son.loop ? actionIcon('loop') : ''}{actionIcon(son.listeners.find((o)=>o.hook===hook.value).action)}</span></div>)}</TableCell>
                )}
              </TableRow> : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>}
  </>
}

export default SonsTab;
