import React, {useContext} from "react";
import "./Interlocuteur.scss";
import Upload from 'components/form/Upload';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import TextLong from 'components/form/TextLong';

function Interlocuteur({ id }) {
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='interlocuteur' ? <>
  <Text name='Nom' docId={id} path={['nom']}/>
  <TextLong name='Présentation' docId={id} path={['presentation']}/>
  <Upload name='Photo' docId={id} path={['photo']}/>
  </> : '';
}

export default Interlocuteur;
