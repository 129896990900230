import React, {useContext,useState} from "react";
import "./Interfaces.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import { SettingsContext } from "providers/SettingsProvider";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Interface from 'components/Interface';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function Interfaces({conversationId}) {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const texts=getCollection('interface');
  const [ filtre,setFiltre ] = useState('');
  const { appState, setAppState } = useContext(SettingsContext);
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,interfacesExpanded:isExpanded ? id : null}});
  };
  const handleAddInterface=()=>{
    let text=newItem('interface');
    save(text);
  }
  const handleDeleteInterface=(text,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le texte d\'interface ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le texte d\'interface '+ text.slug + ' ?'
    }).then((ok)=>{
      if (ok) remove(text);
      else console.log('canceled');
    });
  }
  const handleChange=(v)=>setFiltre(v.toLowerCase());
  return <>
  <Container maxWidth="lg">
    <Typography variant="h1" component="h1">
      Textes d'interface
    </Typography>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddInterface}>
      Nouveau texte d'interface
    </Button>

    <div className='spacer'></div>
    <div>
      <TextField
      label='filtre'
      variant="outlined"
      value={filtre}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
      }}/>
    </div>
    <div className='spacer'></div>

      {texts.filter((o)=>o.texte_court.toLowerCase().indexOf(filtre)!==-1 || o.texte_long.toLowerCase().indexOf(filtre)!==-1 || o.slug.toLowerCase().indexOf(filtre)!==-1).map((text)=><Accordion
      key={text._id}
      expanded={appState.interfacesExpanded === text._id ? true : false}
      onChange={handleExpandedChange(text._id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{text.slug || '(sans id)'}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteInterface(text,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Interface id={text._id}/>
        </AccordionDetails>
      </Accordion>)}
    <div className="big-spacer"/>
  </Container>
  </>
}

export default Interfaces;
