import React, {useContext,useEffect} from "react";
import "./Conversations.scss";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { confirm } from 'components/Confirm'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Conversation from 'components/Conversation';
import { initiales } from 'shared/conversationsUtils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Sortable from 'components/Sortable';


function Conversations({portraitId}) {
  const { getCollection, getDoc, newItem, save, remove } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  useEffect(()=>{
    //console.log('init');
    if (appState && !appState.conversationsExpanded) {
      setAppState((state)=>{return {...state,conversationsExpanded:{[portraitId]:null}}});
    }
  },[appState,setAppState,portraitId])
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,conversationsExpanded:{...state.conversationsExpanded,[portraitId]: isExpanded ? id : null}}});
  };
  const conversations=getCollection('conversation').filter((o)=>o.portraitId===portraitId);
  const portrait=getDoc(portraitId);
  const interlocuteurs=getCollection('interlocuteur').filter((o)=>o.portraitId===portraitId);
  const handleAddConversation=()=>{
    if (portraitId) {
      let conversation=newItem('conversation');
      conversation.portraitId=portraitId;
      save(conversation);
    }
  }
  const handleDeleteConversation=(conversation,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer la conversation ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer la conversation ?'
    }).then((ok)=>{
      if (ok) remove(conversation);
      else console.log('canceled');
    });
  }
  return <>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddConversation}>
        Nouvelle conversation
      </Button>
      <div className='spacer'></div>
      <Sortable
      list={conversations}
      render={(conversation)=>{
        const messages=getCollection('message').filter((o)=>o.conversationId===conversation._id);
        const localInterlocuteurs=[portrait,...interlocuteurs].filter((o)=>{
          let res=false;
          messages.forEach((message, i) => {
            if (message.from===o._id) res=true;
          });
          return res;
        });
        return <Accordion
        key={conversation._id}
        expanded={appState.conversationsExpanded && appState.conversationsExpanded[portraitId] === conversation._id ? true : false}
        onChange={handleExpandedChange(conversation._id)}
        TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className='sort-handle'
          >
            <Typography>{conversation.date || 'Sans date'} - {localInterlocuteurs.map((interlocuteur,i)=>{
              return interlocuteur ? <React.Fragment key={interlocuteur._id}>{i>0 ? ' ':''}<span>{initiales(interlocuteur.nom)}</span></React.Fragment> : '';
            })} {' '}
              <IconButton
                size="small"
                color="inherit"
                onClick={(e)=>handleDeleteConversation(conversation,e)}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Conversation id={conversation._id}/>
          </AccordionDetails>
        </Accordion>}}
      />
  </>
}

export default Conversations;
