import React, {useContext} from "react";
import "./Son.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { useParams } from "react-router-dom";
import SonListener from 'components/SonListener';
import Text from 'components/form/Text';
import Case from 'components/form/Case';
import Fader from 'components/form/Fader';
import Upload from 'components/form/Upload';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { confirm } from 'components/Confirm'
import DeleteIcon from '@mui/icons-material/Delete';

function Son() {
  const { id } = useParams();
  const { getDoc, audioHooks, newItem, savePath } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const doc=getDoc(id);
  const handleExpandedChange=(listenerId) => (event, isExpanded) => {
    setAppState((state)=>{return {...state,listenersExpanded:{...state.listenersExpanded,[id]: isExpanded ? listenerId : null}}});
  };
  const handleAddListener=()=>{
    let listener=newItem('listener');
    savePath(id,['listeners'],[...doc.listeners,listener]);
  }
  const handleDeleteListener=(listener,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le déclencheur ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le déclencheur ?'
    }).then((ok)=>{
      if (ok) {
        const idx=doc.listeners.findIndex((o)=>o._id===listener._id);
        console.log(doc.listeners,listener,idx);
        if (idx>=0) {
          const list=[...doc.listeners];
          list.splice(idx,1);
          savePath(id,['listeners'],list);
        }
      }
      else console.log('canceled');
    });
  }
  return doc && doc.type==='son' ? <>
  <Container maxWidth="md">
    <Typography variant="h1" component="h2">
      Son
    </Typography>
    <Typography variant="h2" component="h2">
      {doc.titre || 'Sans titre'}
    </Typography>
    <div className='spacer'></div>
    <Text name='Titre' docId={id} path={['titre']}/>
    <Upload name='Fichier' docId={id} path={['fichier']} maxFiles={1} type='son' volume={doc.volume}/>
    <div className='spacer'></div>
    <Fader name='Attenuation (dB)' docId={id} path={['volume']}/>
    <Case name='En boucle' docId={id} path={['loop']}/>
    <Case name='Fade In' docId={id} path={['fadeIn']}/>
    {doc.loop && <Text name='Durée du crossfade (ms)' docId={id} path={['crossFadeDuration']}/>}
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddListener}>
      Nouveau déclencheur
    </Button>
    <div className='spacer'></div>
    {doc.listeners.map((listener,i)=>{
      const hook=audioHooks.find((o)=>o.value===listener.hook);
      return <Accordion
      key={listener._id}
      expanded={appState.listenersExpanded && appState.listenersExpanded[id] === listener._id ? true : false}
      onChange={handleExpandedChange(listener._id)}
      TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{hook ? hook.label : '(vide)'}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteListener(listener,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SonListener listener={listener} docId={doc._id} path={['listeners',i]} audioHooks={audioHooks}/>
        </AccordionDetails>
      </Accordion>
    })}
    <div className="big-spacer"/>
  </Container>
  </> : '';
}

export default Son;
