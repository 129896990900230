import React, {useContext,useEffect} from "react";
import "./Conversation.scss";
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Messages from 'components/Messages';

function Conversation({ id }) {
  const { getDoc, getCollection, savePath } = useContext(ModelContext);
  const doc=getDoc(id);
  const portrait=doc ? getDoc(doc.portraitId) : null;
  const interlocuteurs=portrait ? [portrait,...getCollection('interlocuteur').filter((o)=>o.portraitId===portrait._id)].map((o)=>{return {label:o.nom || 'Sans nom',value:o._id}}) : [];
  useEffect(()=>{
    if (doc && !doc.protagoniste && portrait) savePath(doc._id,['protagoniste'],portrait._id);
  },[doc,portrait,savePath])
  return doc && doc.type==='conversation' && doc.protagoniste ? <>
    <Text name='Date' docId={id} path={['date']}/>
    <Select name='Protagoniste' docId={id} path={['protagoniste']} options={interlocuteurs}/>
    <Typography variant="h5" component="h5">
      Messages
    </Typography>
    <Messages conversationId={id}/>
  </> : '';
}

export default Conversation;
