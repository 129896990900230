import React, {useContext, forwardRef} from "react";
import "./Themes.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Sortable from 'components/Sortable';

const CustomGrid = forwardRef((props, ref) => {
  return <Grid container spacing={2} ref={ref}>{props.children}</Grid>;
});

function Themes() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const themes=getCollection('theme');
  const handleAddTheme=()=>{
    let theme=newItem('theme');
    save(theme);
  }
  const handleDeleteTheme=(theme)=>{
    confirm({
      titre: 'Supprimer le thème ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le thème ?'
    }).then((ok)=>{
      if (ok) remove(theme);
      else console.log('canceled');
    });
  }
  return <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h1">
        Thèmes
      </Typography>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddTheme}>
        Nouveau
      </Button>
      <div className='spacer'></div>
      <Sortable wrapper={CustomGrid} list={themes} render={(theme)=><Grid key={theme._id} item xs={12} sm={6} md={4}>
        <Card sx={{ minWidth: 275 }}>
        <CardContent className='sort-handle'>
          <Typography variant="h4" component="h4" gutterBottom>
            {theme.titre || 'Sans titre'}
          </Typography>
        </CardContent>
        <CardActions>
          <Link to={'/theme/'+theme._id}><Button size="small">Modifier</Button></Link>
          <Button size="small" onClick={()=>handleDeleteTheme(theme)}>Supprimer</Button>
        </CardActions>
      </Card>
      </Grid>}/>
      <div className="big-spacer"/>
    </Container>
  </>
}

export default Themes;
