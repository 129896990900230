import React, { useContext,useState,useEffect,useCallback } from "react";
import "./Player.scss";
import ConversationPlayer from 'shared/components/ConversationPlayer';
import { ModelContext } from "providers/ModelProvider";
import { MixContext } from "shared/providers/MixProvider";
import { SettingsContext } from "providers/SettingsProvider";
import ResetIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Scaler from "shared/components/Scaler";

function Player({portraitId}) {
  const { getCollection } = useContext(ModelContext);
  const { appState } = useContext(SettingsContext);
  const { muteAll, setMuteAll } = useContext(MixContext);
  const [ step,setStep ] = useState(0);
  const handleTap=useCallback(()=>{
    setStep(step=>step+1);
  },[setStep]);
  useEffect(()=>{
    if (
      portraitId
      && appState.conversationsExpanded
      && appState.conversationsExpanded[portraitId]
      && appState.messagesExpanded
      && appState.messagesExpanded[appState.conversationsExpanded[portraitId]]
    ) {
      let rank=0;
      const conversations=getCollection('conversation').filter((o)=>o.portraitId===portraitId);
      conversations.forEach((conversation,i)=>{
        rank++;
        const messages=getCollection('message').filter((o)=>o.msgType!=='empty' && o.conversationId===conversation._id);
        messages.forEach((message,j)=>{
          rank++;
          if(appState.messagesExpanded[appState.conversationsExpanded[portraitId]]===message._id) {
            setStep(rank);
          }
        });
      });
    }
  },[appState.messagesExpanded,appState.conversationsExpanded,portraitId,getCollection])
  return <div className='portrait-player-container'>
      <Scaler/>
      <ConversationPlayer portraitId={portraitId} step={step} onTap={handleTap}/>
      <div className="player-tool-bar">
        <IconButton
          size="small"
          color="inherit"
          onClick={()=>setStep(0)}
        >
          <ResetIcon />
        </IconButton>
        <IconButton
          size="small"
          color="inherit"
          onClick={()=>setMuteAll(!muteAll)}
        >
          {muteAll ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </div>
  </div>;
}

export default Player;
