import React, { useEffect, useMemo, useContext, useCallback, useRef } from "react";
import "./SonListener.scss";
import Select from 'components/form/Select';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
const tracks=[];
[...Array(20).keys()].forEach((idx) => {
  tracks.push({label:idx+1,value:'track-'+idx});
});
const actions=[
  {label:'indépendant',value:'play out'},
  {label:'play',value:'play'},
  {label:'pause',value:'pause'},
  {label:'stop',value:'stop'},
  {label:'play cut',value:'play cut'},
  {label:'pause cut',value:'pause cut'},
  {label:'stop cut',value:'stop cut'},
];
function SonListener({ listener, docId, path }) {
  const { contexts } = useContext(ModelContext);
  const { appState,setAppState } = useContext(SettingsContext);
  const init=useRef(false);
  const context=useMemo(()=>appState.listenerContext || (contexts.length>0 ? contexts[0] : null),[appState,contexts]);
  const setContext=useCallback((v)=>{
    const c=contexts.find((o)=>o.value===v);
    if (c) setAppState(state=>{return {...state, listenerContext:c}})
  },[setAppState,contexts]);
  const contextHooks=useMemo(()=>{
    const c=contexts.find((o)=>o.value===context.value);
    return c ? c.hooks : [];
  },[contexts,context]);
  useEffect(()=>{
    if (!init.current && listener && listener.hook && context && context.hooks.findIndex((o)=>o.value===listener.hook)===-1) {
      const newContext=contexts.find((o)=>o.hooks.findIndex((h)=>h.value===listener.hook)!==-1);
      if (newContext) setContext(newContext.value);
      init.current=true;
    }
  },[listener,context,setContext,contexts])
  return listener && listener.type==='listener' ? <>
    {context && <FormControl fullWidth>
      <InputLabel>Contexte</InputLabel>
      <SelectMui
      value={context.value}
      label='Contexte'
      onChange={(e)=>setContext(e.target.value)}
      >
        {contexts.map((option)=><MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
      </SelectMui>
    </FormControl>}
    {contextHooks.length>0 && <Select name='Evenement' docId={docId} path={[...path,'hook']} options={contextHooks}/>}
    <Select name='Piste' docId={docId} path={[...path,'track']} options={tracks}/>
    <Select name='Type' docId={docId} path={[...path,'action']} options={actions}/>
  </> : null;
}

export default SonListener;
