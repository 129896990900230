import React, { useContext, useState, useEffect } from "react";
import "./Case.scss";
import { ModelContext } from "providers/ModelProvider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function Case({docId,path,name=''}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const handleChange=(v)=>{
    setValue(v);
    savePath(docId,path,v);
  };
  useEffect(()=>{
    setValue(dbValue);
  },[dbValue,setValue]);
  return (
    <div className="input-case">
      <FormControlLabel
      control={<Checkbox
        checked={Boolean(value)}
        onChange={(e)=>handleChange(e.target.checked)}
      />}
      label={name} />
    </div>
  )
}

export default Case;
