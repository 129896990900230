import React, { useContext, useState, useEffect, useRef } from "react";
import "./MarkDown.scss";
import "shared/components/MarkDown.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import MuiMarkdown from 'mui-markdown';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { parseIcons } from 'shared/Utils';

function MarkDown({docId,path,name='',parse=(v)=>null, background='white', images=[], className=''}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path) || '';
  const [ value,setValue ] = useState(dbValue);
  const updateTimer=useRef(null);
  const saveTimer=useRef(null);
  const parseTimer=useRef(null);
  const handleChange=(v)=>{
    setValue(v);
    if (updateTimer.current) clearTimeout(updateTimer.current);
    if (parseTimer.current) clearTimeout(parseTimer.current);
    parseTimer.current=setTimeout(()=>{
      parse(v);
    },3000);
    if (saveTimer.current) clearTimeout(saveTimer.current);
    saveTimer.current=setTimeout(()=>{
      savePath(docId,path,v);
    },1000);
  };
  useEffect(()=>{
    if (updateTimer.current) clearTimeout(updateTimer.current);
    updateTimer.current=setTimeout(()=>{
      setValue(dbValue);
      console.log('update!')
    },2000);
  },[dbValue,setValue]);
  const saved= dbValue===value;
  return (
    <div className={"input-markdown "+background}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
          multiline
          minRows={3}
          fullWidth
          label={name}
          variant="outlined"
          value={value}
          onChange={(e)=>handleChange(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end"><div style={{width:'30px'}}>{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</div></InputAdornment>,
          }}/>
        </Grid>
        <Grid item xs={6} className="markdown-preview">
          <Typography variant="h3">
            Aperçu&nbsp;:
          </Typography>
          <Paper sx={{padding:'15px',marginTop:'15px'}} className={className}>
            <MuiMarkdown>{parseIcons(value,images)}</MuiMarkdown>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default MarkDown;
