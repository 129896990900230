import React, {useContext} from "react";
import "./Theme.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { useParams } from "react-router-dom";
import TextLong from 'components/form/TextLong';

function Theme() {
  const { id } = useParams();
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='theme' ? <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h2">
        Thème
      </Typography>
      <Typography variant="h2" component="h2">
        {doc.titre || 'Sans titre'}
      </Typography>
      <div className='spacer'></div>
      <TextLong name='Titre' docId={id} path={['titre']}/>
    </Container>
  </> : '';
}

export default Theme;
