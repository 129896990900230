import React, {useContext, forwardRef} from "react";
import "./Portraits.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";
import Sortable from 'components/Sortable';

const CustomGrid = forwardRef((props, ref) => {
  return <Grid container spacing={2} ref={ref}>{props.children}</Grid>;
});


function Portraits() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const portraits=getCollection('portrait');
  const themes=getCollection('theme');
  const handleAddPortrait=()=>{
    let portrait=newItem('portrait');
    save(portrait);
  }
  const handleDeletePortrait=(portrait)=>{
    confirm({
      titre: 'Supprimer le portrait ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le portrait ?'
    }).then((ok)=>{
      if (ok) remove(portrait);
      else console.log('canceled');
    });
  }
  const handleChooseTheme=(theme)=>{
    setAppState((state)=>{return {...state,choosenTheme:theme}});
  }
  return <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h1">
        Portraits
      </Typography>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddPortrait}>
        Nouveau
      </Button>
      <div className='spacer'></div>
      <div className='theme-chooser'>
        <Button size="small" variant={!Boolean(appState.choosenTheme) ? "contained" : "outlined"} onClick={()=>handleChooseTheme(null)}>Tout</Button>
        {themes.map((theme)=><Button key={theme._id} size="small"  variant={appState.choosenTheme===theme ? "contained" : "outlined"} onClick={()=>handleChooseTheme(theme)}>{theme.titre}</Button>)}
        <Button size="small" variant={appState.choosenTheme===-1 ? "contained" : "outlined"} onClick={()=>handleChooseTheme({_id:-1})}>Sans theme</Button>
      </div>
      <div className='spacer'></div>
      <Sortable
      wrapper={CustomGrid}
      list={portraits.filter((o)=>!Boolean(appState.choosenTheme) || o.themeId===appState.choosenTheme._id || (appState.choosenTheme._id===-1 && !o.themeId))}
      render={(portrait)=><Grid key={portrait._id} item xs={12} sm={6} md={4}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent className='sort-handle'>
            <Typography variant="h4" component="h4" gutterBottom>
              {portrait.nom || 'Sans nom'}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to={'/portrait/'+portrait._id}><Button size="small">Modifier</Button></Link>
            <Button size="small" onClick={()=>handleDeletePortrait(portrait)}>Supprimer</Button>
          </CardActions>
        </Card>
      </Grid>}/>
    <div className="big-spacer"/>
    </Container>
  </>
}

export default Portraits;
