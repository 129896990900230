import React, {useContext, forwardRef} from "react";
import "./Liens.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";
import Sortable from 'components/Sortable';

const CustomGrid = forwardRef((props, ref) => {
  return <Grid container spacing={2} ref={ref}>{props.children}</Grid>;
});

function Liens() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const liens=getCollection('lien');
  const handleAddLien=()=>{
    let message=newItem('lien');
    save(message);
  }
  const handleDeleteLien=(lien,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le lien ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le lien ?'
    }).then((ok)=>{
      if (ok) remove(lien);
      else console.log('canceled');
    });
  }
  return <>
  <Container maxWidth="lg">
    <Typography variant="h1" component="h1">
      Liens
    </Typography>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddLien}>
      Nouveau Lien
    </Button>
    <div className='spacer'></div>
    <Sortable
    wrapper={CustomGrid}
    list={liens}
    render={(lien)=><Grid key={lien._id} item xs={12} sm={6} md={4}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className='sort-handle'>
          <Typography variant="h4" component="h4" gutterBottom>
            {lien.titre || 'Sans titre'}
          </Typography>
          <p>({lien.slug})</p>
        </CardContent>
        <CardActions>
          <Link to={'/lien/'+lien._id}><Button size="small">Modifier</Button></Link>
          <Button size="small" onClick={(e)=>handleDeleteLien(lien,e)}>Supprimer</Button>
        </CardActions>
      </Card>
    </Grid>}/>
  </Container>
  </>
}

export default Liens;
