import React, { useContext, useState, useEffect, useRef } from "react";
import "./Fader.scss";
import { ModelContext } from "providers/ModelProvider";
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function Fader({docId,path,name=''}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const saveTimer=useRef(null);
  const handleChange=(v)=>{
    console.log(v);
    setValue(v);
    if (saveTimer.current) clearTimeout(saveTimer.current);
    saveTimer.current=setTimeout(()=>{
      savePath(docId,path,v);
    },1000);
  };
  useEffect(()=>{
    setValue(dbValue);
  },[dbValue,setValue]);
  const calculateValue = (v)=>{
    if(isNaN(v)) v=0;
    if (v===-80) return 0;
    else return Math.pow(2,v/6);
  }
  const calculateReverseValue = (v)=>{
    if (!v) return 0;
    let res = 6*Math.log(v) / Math.log(2);
    let resRounded=0;
    if (res<=-80) resRounded=-80;
    else resRounded=Math.round(res*100)/100;
    return resRounded;
  }
  const saved= dbValue===value;
  return (
    <div className="input-number">
      <TextField
      fullWidth
      label={name}
      variant="outlined"
      value={-calculateReverseValue(value)}
      typ="number"
      onChange={(e,v)=>handleChange(calculateValue(-e.target.value))}
      InputProps={{
        startAdornment: <InputAdornment position="start">-</InputAdornment>,
        endAdornment: <InputAdornment position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>,
      }}/>
    </div>
  )
}

export default Fader;
